@use '@angular/material' as mat;

@import '../../styles/vars'; // ruta del archivo de variables

//* NO TOCAR de aquí para abajo si no se sabe lo que se hace ------------------------------------------------
@include mat.core();

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: rgba(white, 0.87);

$primary-palette: (
  50: lighten($primary-color, 13%),
  100: lighten($primary-color, 12%),
  200: lighten($primary-color, 11%),
  300: lighten($primary-color, 10%),
  400: lighten($primary-color, 9%),
  500: lighten($primary-color, 8%),
  600: lighten($primary-color, 7%),
  700: lighten($primary-color, 6%),
  800: lighten($primary-color, 5%),
  900: $primary-color,
  A100: lighten($primary-color, 14%),
  A200: lighten($primary-color, 10%),
  A400: lighten($primary-color, 2%),
  A700: darken($primary-color, 2%),
  // * Color oscuro
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$second-palette: (
  50: lighten($second-color, 13%),
  100: lighten($second-color, 12%),
  200: lighten($second-color, 11%),
  300: lighten($second-color, 10%),
  400: lighten($second-color, 9%),
  500: lighten($second-color, 8%),
  600: lighten($second-color, 7%),
  700: lighten($second-color, 6%),
  800: lighten($second-color, 5%),
  900: $second-color,
  A100: lighten($second-color, 14%),
  A200: lighten($second-color, 10%),
  A400: lighten($second-color, 2%),
  A700: darken($second-color, 2%),
  // * Color oscuro
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$ng-project-primary: mat.define-palette($primary-palette);
$ng-project-accent: mat.define-palette($second-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ng-project-warn: mat.define-palette(mat.$red-palette);

$ng-project-theme: mat.define-light-theme(
  (
    color: (
      primary: $ng-project-primary,
      accent: $ng-project-accent,
      warn: $ng-project-warn,
    )
  )
);

@include mat.all-component-themes($ng-project-theme);


//* MATERIAL COLOR DATEPICKER
.mat_form-type_1 {
  .mat-form-field-underline { display: none; }
  .mat-datepicker-toggle-active { color: $primary-color; }
}
.mat-calendar-body-selected { background-color: $primary-color; }
.mat-calendar-body-in-range::before { background: rgba($primary-color, 0.2); }
.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) { background-color: rgba($primary-color, 0.3); }

// Animate CSS
@import url('../../styles/animate.css');

.animate__init { animation-delay: 0.3s; }
.loader__page { width: 40px; height: 40px; position: relative; margin: auto;
  &.loader_one {
    &::before, &::after { content: ''; position: absolute; border-radius: 50%; width: 100%; height: 100%; top: calc(50% - 15px); left: calc(50% - 15px); animation-duration: 3s; animation-timing-function: linear; animation-iteration-count: infinite; }
    &::before { border-style: solid; border-width: 3px 3px 3px 0; border-color: $primary-color transparent transparent; transform: scale(0.3) rotate(0deg); opacity: 0.5; animation-name: foregroundCircle; }
    &::after { background: $primary-color; opacity: 0.5; transform: scale(0); animation-name: backgroundCircle; }
  }
}

@keyframes foregroundCircle{
  0% { transform: scale(0.3) rotate(0deg) }
  12.5% { transform: scale(0.3) rotate(180deg); }
  25%, 50% { opacity: 1; }
  50% { transform: scale(1) rotate(720deg); }
  100% { transform: scale(0.3) rotate(1800deg); opacity: 0.5; }
}
@keyframes backgroundCircle {
  12.5% { transform: scale(0.3) }
  90%, 100% { transform: scale(2); opacity: 0; }
}
.swiper-pagination-bullet-active { background: $primary-color; }
.loader-line {
  width: 200px;
  height: 3px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  margin: 100px auto;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.loading_line_content { position: relative; overflow: hidden; pointer-events: none;
  &::before { content: ""; position: absolute; top: 0; left: -50%; height: 3px; width: 8%; background-color: $primary-color; -webkit-animation: line_loading 1.1s linear infinite; -moz-animation: line_loading 1.1s linear infinite; animation: line_loading 1.1s linear infinite; -webkit-border-radius: 20px; -moz-border-radius: 20px; border-radius: 20px; }
}

@keyframes line_loading {
  0% { left: -30%; }
  50% { left: 20%; width: 80%; }
  100% { left: 100%; width: 100%; }
}

.loading_wrapper { position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 9999999999999999;
  &.not_available_network {
    .loading__content { width: 250px;
      .not_available_network_icon { width: 100%; display: block; text-align: center; font-size: 6rem; color: $primary-color; line-height: 1; }
    }
  }
  .backdrop_loading { background-color: rgba(0, 0, 0, 0.3); z-index: 1; pointer-events: none; position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
  .loading__content { display: block; width: auto; z-index: 2; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); background: #fff; padding: 1.5rem 2rem; border-radius: 15px; text-align: center;
    h3 { display: block; width: 100%; text-align: center; font-size: 1.5rem; font-weight: 700; }
    p { display: block; margin: 0; text-align: center; width: 100%; font-weight: 500; font-size: 16px; }
  }
}

// Loading Default
.loads-ellipsis { margin: 0 auto; display: block; position: relative; width: 80px; height: 80px;
  div { position: absolute; top: 33px; width: 13px; height: 13px; border-radius: 50%; background: $primary-color; animation-timing-function: cubic-bezier(0, 1, 1, 0);
    &:nth-child(1) { left: 8px; animation: lds-ellipsis1 0.6s infinite; }
    &:nth-child(2) { left: 8px; animation: lds-ellipsis2 0.6s infinite; }
    &:nth-child(3) { left: 32px; animation: lds-ellipsis2 0.6s infinite; }
    &:nth-child(4) { left: 56px; animation: lds-ellipsis3 0.6s infinite; }
  }
}
@keyframes lds-ellipsis1 {
  0%    { transform: scale(0); }
  100%  { transform: scale(1); }
}
@keyframes lds-ellipsis3 {
  0%    { transform: scale(1); }
  100%  { transform: scale(0); }
}
@keyframes lds-ellipsis2 {
  0%    { transform: translate(0, 0); }
  100%  { transform: translate(24px, 0); }
}

// Loading 2
.loadingio-spinner-dual-ball { width: 100px; height: 100px; display: inline-block; overflow: hidden; background: transparent; }
.ldio-dualball div { position: absolute; width: 40px; height: 40px; border-radius: 50%; top: 30px; left: 10px; }
.ldio-dualball div:nth-child(1) { background: #e90c59; animation: ldio-dualball 1s linear infinite; animation-delay: -0.5s; }
.ldio-dualball div:nth-child(2) { background: #46dff0; animation: ldio-dualball 1s linear infinite; animation-delay: 0s; }
.ldio-dualball div:nth-child(3) { background: #e90c59; animation: ldio-dualball-o 1s linear infinite; animation-delay: -0.5s; }
.ldio-dualball { width: 100%; height: 100%; position: relative; transform: translateZ(0) scale(1); backface-visibility: hidden; transform-origin: 0 0; }
.ldio-dualball div { box-sizing: content-box; }
@keyframes ldio-dualball-o {
  0%    { opacity: 1; transform: translate(0 0) }
  49.99% { opacity: 1; transform: translate(40px,0) }
  50%    { opacity: 0; transform: translate(40px,0) }
  100%    { opacity: 0; transform: translate(0,0) }
}
@keyframes ldio-dualball {
  0% { transform: translate(0,0) }
  50% { transform: translate(40px,0) }
  100% { transform: translate(0,0) }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from { -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes rotating {
  from { -ms-transform: rotate(0deg); -moz-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg); }
  to { -ms-transform: rotate(360deg); -moz-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); transform: rotate(360deg); }
}

.rotating { -webkit-animation: rotating 2s linear infinite; -moz-animation: rotating 2s linear infinite; -ms-animation: rotating 2s linear infinite; -o-animation: rotating 2s linear infinite; animation: rotating 2s linear infinite; }

:root {
  --rotate: 0deg;
  --color: #000000;
  --item-width: 10px;
  --item-height: 1px;
}
@mixin on-circle($item-count, $circle-size, $item-width, $item-height) {
  position: relative;
  width: $circle-size;
  height: $circle-size;
  padding: 0;
  border-radius: 50%;
  transform: rotate(210deg);
  padding: 20px;

  > * {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $item-width;
    height: $item-height;
    $angle: calc(360 / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        animation: background 1s ease calc($i / $item-count) + s infinite;
        transform: rotate($rot * 1deg) translate(calc($circle-size / 2)) rotate($i * $angle + deg) rotate($rot * -1deg);
      }
      $rot: $rot + $angle;
    }
  }
}
@keyframes background {
  from { background: var(--color); }
  to { background: transparent; }
}
.loader_in {
  @include on-circle($item-count: 40, $circle-size: 60px, $item-width: var(--item-width), $item-height: var(--item-height));
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > div {
    transform-origin: top center;
    background: transparent;
  }
}

.loader_content { position: relative; width: 600px; max-width: 100%; display: block; margin: auto; border: 1px solid #ebebeb; background-color: #fff;
  &::before { content: ""; display: block; padding-bottom: 100%; }
  .loader_in { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); max-width: 100%; }
  img { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 100%; height: 100%; object-fit: contain; object-position: center; opacity: 0; visibility: hidden;
    &.show_qr { opacity: 1; visibility: visible; }
  }
}

html { background: $primary-color;
  &.camera-active { background: transparent !important;
    body.webapp-site {
      --background: transparent !important;
      --ion-background-color: transparent !important;
      background: transparent !important;

      .bg_lines { display: none; }
      ion-app.main-site {
        background: transparent !important;
      }
    }
  }
  &.splashscreen body.webapp-site app-root .root__page {
    width: 100% !important;
    border-radius: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    box-shadow: none !important;
    background-color: var(--primary-color) !important;
    ion-app {
      background-color: var(--primary-color);
    }
  }
}
body.webapp-site {
  position: relative;
  color: #1C1C1C;
  background: $primary-color !important;
  line-height: inherit;
  
  ion-header:not(.header__custom) {
    box-shadow: none;
    ion-toolbar {
      --min-height: unset;
    }
  }
  .ion-page {
    overflow: hidden;
  }

  .content_camera { position: relative; width: 100%; height: 100%;
    .camera__web { display: flex; width: 100%; height: 100%;
      video { display: block; margin: auto; width: 100%; height: 100%; object-fit: scale-down; object-position: center; }
    }
    .camera__app { display: flex; width: 100%; height: 100%;
      video { display: block; margin: auto; width: 100%; height: 100%; object-fit: cover; object-position: center; }
    }
    span { width: 100%; height: 100%; display: block; position: absolute; top: 0; left: 0;
      .camera__esq { position: absolute; width: 42px; height: 42px; object-fit: contain; }
      .camera__esq_1 { top: 10%; left: 10%; }
      .camera__esq_2 { top: 10%; right: 10%; }
      .camera__esq_3 { bottom: 15%; right: 10%; }
      .camera__esq_4 { bottom: 15%; left: 10%; }
      .camera__selfie { position: absolute; top: 16%; left: 50%; transform: translate(-50%, 0); width: 400px; max-width: 88%; opacity: 0.4; }
      .content__card { width: 440px; max-height: 95%; max-width: 95%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);
        &.type__2 { 
          &::before { padding-bottom: 50%; }
        }
        &::before { content: ""; display: block; padding-bottom: 64%; }
        .camera__esq_1 { top: 0%; left: 0%; }
        .camera__esq_2 { top: 0%; right: 0%; }
        .camera__esq_3 { bottom: 0%; right: 0%; }
        .camera__esq_4 { bottom: 0%; left: 0%; }
      }
    }
  }
  .btns_camera { display: grid; grid-template-columns: repeat(3,minmax(0, 1fr)); width: 450px; max-width: 100%; margin: auto; align-items: center; justify-items: center; gap: 1rem; color: #fff; padding: 1.5rem 1rem;
    &.capture_image_only { display: flex; align-items: center; justify-content: center; }
    button { padding: 1rem; appearance: none; height: auto; line-height: normal; font-size: 25px; pointer-events: auto;
      &.camera_btn { font-size: 33px; border-radius: 50%; background: var(--primary-color); width: 4.5rem; height: 4.5rem; display: flex; align-items: center; justify-content: center; }
    }
  }

  button.btn__icon { appearance: none; height: auto; line-height: normal; display: inline-flex; align-items: center; gap: 6px;
    &.white__icon { color: #fff; }
    &.black__icon { color: #000; }
    &.primary__icon { color: var(--primary-color); }
    &.icon__only { padding: 0.7rem; border-radius: 8px; font-size: 20px;
      &.white__icon { background: rgba(0, 0, 0, 0.17); }
      &.black__icon { background: rgba(255, 255, 255, 0.17); }
      &.primary__btn { background-color: var(--primary-color); }
      &.second__btn { background-color: var(--second-color); }
      &.third__btn { background-color: var(--third-color); }
      &.fourth__btn { background-color: var(--fourth-color); }
      &.full__round { border-radius: 50%; }
    }
    span.text__int { font-size: 15px; }
    &.primary__btn { background-color: var(--primary-color); }
    &.second__btn { background-color: var(--second-color); }
    &.third__btn { background-color: var(--third-color); }
    &.fourth__btn { background-color: var(--fourth-color); }
    &.tooltip__onload {
      position: relative;
      .tooltip__content {
        transform: scale(0.0) translateY(-50%);
        transform-origin: center 0;
        transition: all ease-in-out 0.2s;

        font-size: 14px;
        padding: 8px 10px;
        border-radius: 10px;
        position: absolute;
        top: 50%;
        right: calc(100% + 10px);
        background-color: var(--primary-color);
        color: #fff;
        width: fit-content;
        height: auto;
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -10px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent transparent var(--primary-color);
        }
      }
      &.active {
        .tooltip__content {
          transform: scale(1) translateY(-50%);
        }
      }
    }
  }
  .content__wrap { left: 0; right: 0; top: 0; bottom: 0; display: flex; position: absolute; flex-direction: column; justify-content: space-between; contain: layout size style; overflow: hidden; }
  // New content page contrain
  .content__header { display: block; position: relative; -ms-flex-order: -1; order: -1; width: 100%; z-index: 10;
    &.sticky__header { position: sticky; top: 0; }
    &.header__int { display: flex; align-items: center; gap: 1rem }
    ion-searchbar { width: 100%; box-shadow: none; --box-shadow: none; border: 1px solid #ddd; border-radius: 6px; overflow: hidden; padding: 0; text-align: left; }
  }
  .content__body { display: block; position: relative; flex: 1 1 0%; width: 100%; height: 100%; contain: size style; overflow-x: unset; overflow-y: auto; overscroll-behavior-y: contain; will-change: scroll-position; touch-action: pan-x pan-y pinch-zoom;
    &.fullscreen { position: absolute; }
    &.no_scroll { overflow: hidden; }
    &.show__scrollbar { overflow-y: auto; overscroll-behavior-y: initial; will-change: initial; touch-action: initial;
      &::-webkit-scrollbar { width: 8px; height: 8px; }
      &::-webkit-scrollbar-track { box-shadow: inset 0 0 4px rgba(128,128,128,0.20); border-radius: 10px; }
      &::-webkit-scrollbar-thumb { background: var(--primary-color); border-radius: 10px; }
      &::-webkit-scrollbar-thumb:hover { background: var(--primary-color); }
    }
  }
  .content__footer { display: block; position: relative; -ms-flex-order: 1; order: 1; width: 100%; z-index: 10; }

  // bg resaltado de color azul al hacer click en un "button" o "a"
  app-root, .root__page { display: block; height: 100%; width: 100%; }
  .root__page {
    &:not(.auth-page) {
      app-auth {
        app-login, app-register, app-forgot-password, app-register-persona, app-register-empresa, app-register-waiting-form, app-register-app, app-camera {
          .content__login { grid-template-columns: minmax(0,1fr);
            .more_information { display: none; }
          }
        }
      }
    }
  }
  app-auth {
    app-login, app-register, app-forgot-password, app-register-persona, app-register-empresa, app-register-waiting-form, app-register-app, app-camera { height: 100%; }
    app-camera { left: 0; right: 0; top: 0; bottom: 0; display: flex; position: absolute; flex-direction: column; justify-content: space-between; contain: layout size style; overflow: hidden; }
  }
  .main-site { position: absolute; top: 0; left: 0; right: 0; bottom: 0; contain: unset; width: 100%; height: 100%; background-color: #F9F9F9;
    ion-router-outlet { position: unset; display: block; contain: unset; box-sizing: border-box; height: 100%; width: 100%;
      ion-app, .ion-page { height: 100%; }
    }
    app-introduction { height: 100%; }
    ion-split-pane { --side-width: 280px; }
    ion-app { display: block; position: unset; width: inherit; height: inherit; contain: unset; }
  }

  .can-go-back .content_btn-back ion-back-button { display: initial; }

  ion-modal {
    .ion-page { display: contents;
      &:has(.content__body) { display: flex; }
      &:has(ion-content) { contain: layout size style; }
    }
    &.success__modal { --height: 100%; }
    &.middle__modal { --width: fit-content; --min-width: 250px; --max-width: calc(100% - 1rem); --max-height: 100%; --height: fit-content; --border-radius: 6px; --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4); --overflow: hidden auto;
      .wrapper { padding: 1.5rem; position: relative; }
      &.selectTypeImage { --width: 400px; --max-width: calc(100% - (1rem * 2)); }
    }
    &.modal__adjust { --width: fit-content; --min-width: 250px; --max-width: calc(100% - 1.2rem); --max-height: calc(100% - 1.2rem); --height: fit-content; --border-radius: 6px; --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4); --overflow: hidden auto;
      .wrapper { position: relative; }
      .content__body { contain: layout; }
    }
    &.modal__sheet { --border-radius: 10px 10px 0 0;
      &.ios { --border-radius: 10px; }
      // .ion-page { height: auto; }
      .wrapper { padding: 1.5rem; position: relative; }
    }
    &.not_complete_screen { --border-radius: 10px 10px 0 0; --max-height: 100%; align-items: flex-end; height: auto; --height: auto;
      &.ios { --border-radius: 10px 10px 0 0; }
      .ion-page { height: auto; min-width: 100%; }
      &::part(content) { display: flex; }
    }
    &.complete__modal { --height: 100%; --width: 100%; }
    &.download__modal, &.alert__modal {
      --overflow: unset;
      --background: transparent;
      --border-radius: 20px;
      --width: 400px;
      --max-width: calc(100% - (1rem * 2));
      ion-page {
        overflow: unset;
      }
    }
  }
  ion-alert {
    .alert-wrapper {
      .alert-button-group {
        .alert-button { --ion-color-primary: var(--primary-color); }
      }
    }
  }
  ion-action-sheet {
    .action-sheet-wrapper {
      .action-sheet-container {
        .action-sheet-group:not(.action-sheet-group-cancel) { padding: 1rem;
          button { --button-background: transparent; --button-background-selected: transparent; --button-color: #1C1C1C !important; color: #1C1C1C !important; border-radius: 8px;
            &.action-sheet-selected { background: $primary-color; color: #fff !important; --button-color: #fff !important; }
          }
        }
        .action-sheet-group.action-sheet-group-cancel button { color: $primary-color !important; --button-color: $primary-color !important; }
      }
    }
  }
  .mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] { background: transparent !important; }
  .mat-step-header .mat-step-icon { background-color: #c3c3c3; }
  .mat-step-header .mat-step-icon-selected,
  .mat-step-header .mat-step-icon-state-done,
  .mat-step-header .mat-step-icon-state-edit {
    background-color: var(--primary-color);
  }
  mat-stepper {
    &.stepper_not-step-header .mat-horizontal-stepper-header-container { display: none; opacity: 0; height: 0; width: 0; pointer-events: none; position: absolute; }
    &.stepper_not-change-header mat-step-header.mat-horizontal-stepper-header { pointer-events: none !important; }
  }

  .mat-form-field.mat-focused {
    .mat-form-field-label {
      color: var(--second-color);
    }
    .mat-form-field-ripple {
      background-color: var(--second-color);
    }
  }

  .content_menu-items { display: flex; flex-flow: column; gap: 1.5rem; width: 100%; padding: 1rem;
    ion-menu-toggle { width: 100%; display: block;
      button { padding: 0.65rem 1rem; display: grid; grid-template-columns: minmax(0,16px) minmax(0,1fr); gap: 0.5rem; align-items: center; line-height: normal; width: 100%; text-align: left; cursor: pointer; font-weight: 400; font-size: 15px; color: #4B4B4B; transition: all ease-in-out 0.2s;
        &:hover { color: $primary-color; }
        span { display: inline-block; max-width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
      }
    }
    .group__items-menu { width: 100%;
      h4 { display: block; width: 100%; margin: 0; padding: 0 0 1rem; font-weight: 500; font-size: 18px; color: #4B4B4B; }
      .group__items { padding: 0 1rem; display: flex; flex-flow: column; gap: 0.5rem; width: 100%; }
    }
  }
  .form__inputs-date {
    .mat-form-field-infix { pointer-events: none; }
  }
  .form__inputs-select {
    mat-select .mat-select-trigger {
      padding: 0.8rem 1rem;
      height: auto;
    }
  }
  .hidden_two-lines { overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2;-webkit-box-orient: vertical; }
  .hidden_three-lines { overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 3;-webkit-box-orient: vertical; }
  .swiper_type2 {
    .swiper-pagination { position: absolute; bottom: unset; top: 10px; }
  }
  .content_invoices { width: 100%; height: auto;
    .invoice { position: relative; display: block;
      input[type="radio"], input[type="checkbox"] { position: absolute; top: 0; left: 0; width: 100%; height: 100%; appearance: none; opacity: 0; z-index: 2; cursor: pointer;
        &:checked ~ .detail_invoice { background-color: $primary-color; color: #fff; }
      }
      mat-checkbox { position: absolute; top: 0; left: 0; width: 0; height: 0; pointer-events: none; opacity: 0; visibility: hidden; }
      .check_invoice { display: none; }
      .detail_invoice { display: flex; flex-flow: column; gap: 0.8rem; border: 1px solid #ddd; padding: 1.5rem; border-radius: 10px; font-size: 13px; cursor: pointer;
        &.no_pay { background-color: #f4f3f3; pointer-events: none; }
        &.checked_item { background-color: $primary-color; color: #fff; }
        h4 { font-size: 16px; font-weight: 600; margin: 0; }
        p { font-size: 14px; margin: 0; }
      }
    }
  }

  .content_segment ion-segment { --background: #fff;
    ion-segment-button { --indicator-box-shadow: transparent !important; --border-radius: 2rem !important; padding: 0.5rem 0.1em !important; --color: #b4b3b3; --color-checked: var(--primary-color) !important; --indicator-color: var(--primary-color) !important; --min-height: 0 !important;
      &::part(indicator) { height: 2px; position: absolute; top: unset; bottom: 0; left: 0; width: 100%; }
      &.seg_pending { --color-checked: #F78F14 !important; --indicator-color: #F78F14 !important; }
      &.seg_approved { --color-checked: #00B376 !important; --indicator-color: #00B376 !important; }
      &.seg_rejected { --color-checked: #333333 !important; --indicator-color: #333333 !important; }
      ion-label { font-size: 15px !important; font-weight: 500; }
    }
  }
  .status_item { background-color: #dedede;
    &.pending { background-color: #F78F14 !important; }
    &.approved { background-color: #00B376 !important; }
    &.rejected { background-color: $primary-color !important; }
  }
  .payment_status {
    span { -webkit-mask-repeat: no-repeat; mask-repeat: no-repeat; -webkit-mask-size: contain; -webkit-mask-position: center; mask-size: contain; mask-position: center; background-color: #000; }
    &.pending { color: #F78F14;
      span { background-color: #F78F14 !important; }
    }
    &.approved { color: #00B376;
      span { background-color: #00B376 !important; }
    }
    &.rejected { color: $primary-color;
      span { background-color: $primary-color !important; }
    }
  }
  .accordion_style {
    mat-expansion-panel { padding: 0; padding-top: 0.5rem; margin: 0 !important; box-shadow: none; background: transparent; border-radius: 0;
      &.mat-expansion-panel-spacing { margin: 0 !important; }
      &.mat-expanded {
        mat-expansion-panel-header .icon__dropdown img { transform: rotate(90deg); }
      }
      mat-expansion-panel-header { padding: 0 !important; padding-bottom: 0.5rem !important; background: transparent !important;
        .icon__dropdown img { width: auto; max-width: 100%; display: block; margin: auto; transition: all ease-in-out 0.25s; transform: rotate(0deg); }
      }
      .mat-expansion-panel-body { padding: 0 !important; }
    }
  }

  .form_ion_normal {
    .item__input {
      ion-item { display: block; width: 100%; --background: transparent; --padding-start: 0; --inner-padding-end: 0; --border-width: 0 0 0 0; --show-full-highlight: 0; --highlight-background: transparent;
        &::part(native) { padding: 0; }
        ion-select {
          max-width: 100%;
          &::part(placeholder) { padding: 0 15px 0 0; }
        }
      }
      ion-select {
        --padding-top: 15px;
        --padding-bottom: 15px;
        --padding-start: 15px;
        --padding-end: 15px;
      }
    }
  }
  .form__group { padding: 0 0 0.7rem 0; width: 100%;
    .item__input { width: 100%; display: block;
      label { font-size: 16px; font-weight: 400;
        &.required {
          &::after { content: " *"; color: red; }
        }
      }
      ion-select { max-width: 100%; width: 100%; padding: 0.7rem 1rem; background: #F9F9F9; border-radius: 5px;
        &::part(placeholder), &::part(text) { padding: 0 15px 0 0; font-size: 15px; line-height: 17.58px; min-height: 17.58px; }
      }
      input:not([type="checkbox"]), input:not([type="radio"]), .input_div { padding: 0.7rem 1rem; font-size: 15px; line-height: 17.58px; width: 100%; display: block; background: #F9F9F9; border-radius: 5px; outline: none; }
      &[disabled="true"] {
        input, ion-select, .input_div { background-color: #efefef; pointer-events: none; }
      }
    }
    .item__btn { padding: 0; padding-top: 1rem; width: 100%; display: block; }
    .input_content {
      input { pointer-events: none; }
      &.type_field { padding: 1rem 1.5rem; background: #F9F9F9; /* border: 1px solid #d9d9d9;*/ border-radius: 10px; /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.10); */ display: flex; flex-flow: column; gap: 0.5rem; width: 100%;
        h5 { font-size: 14px; font-weight: 600; margin: 0; line-height: 1.6;
          small, span { font-size: 14px; font-weight: 300; }
        }
      }
      .invoices__items { display: flex; flex-flow: column; width: 100%; gap: 0;
        .invoices__item { width: 100%; padding: 10px;
          &:not(:last-child) { border-bottom: 1px solid #ddd; }
        }
      }
    }
  }
  .text-two-hidden { display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; text-overflow: ellipsis; overflow: hidden; }

  // Qhantuy Empresa App
  ion-alert {
    .alert-title { font-size: 20px; }
    .alert-message { font-size: 16px; }
  }
  ion-select {
    min-height: auto;
    &::part(icon) { position: absolute; top: 50%; right: 10px; transform: translateY(-50%); }
    &::part(text) { position: relative; display: block; width: 100%; padding: 0 15px 0 0; }
  }
  // ion-menu { --width: 100%; }
  .hide_scrollbar { -ms-overflow-style: none; scrollbar-width: none;
    &::-webkit-scrollbar { display: none; }
  }

  .content_btn-back { width: 40px; position: relative;
    &::before { content: ""; display: block; padding-bottom: 100%; }
    ion-back-button { min-width: unset; max-width: 100%; width: 100%; height: 100%; position: absolute; top: 0; left: 0;
      &::part(native) { background-color: $primary-color; border-radius: 4px; color: #fff; }
    }
  }
  .badge {
    display: flex; align-items: center; justify-content: center; text-align: center; width: auto; min-width: 30px; height: 30px; border-radius: 10rem; padding: 0 5px; background-color: $primary-color; color: #fff;
    &.type__small {
      font-size: 12px;
      min-width: 22px;
      height: 22px;
    }
  }
  .content_tabs { text-align: center; width: 100%; z-index: 12; display: block; max-width: 500px; margin: 0 auto;
    .content_tabs-wrapper { width: 100%; display: grid; grid-template-columns: minmax(0,1.8fr) minmax(0,1fr) minmax(0,1.8fr); filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.08));
      .tab_one { width: 100%; background: #fff; color: #4B4B4B;
        &.tab_first { border-top-right-radius: 10px; }
        &.tab_last { border-top-left-radius: 10px; }
        .tab_int { padding: 1rem 0.25rem; position: relative;
          &.disabled { pointer-events: none;
            img { filter: grayscale(1); opacity: 0.5; }
          }
          &.notifications::before { content: ""; position: absolute; top: 10px; right: 10px; width: 5px; height: 5px; border-radius: 100%; background-color: $primary-color; }
          &.active {
            .overlay { opacity: 0.1; }
          }
          .overlay { width: 100%; height: 100%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%) scaleX(0.9) scaleY(0.85); opacity: 0; z-index: 0; transition: all ease-in-out 0.3s; background: #000; border-radius: 8px; }
          .content_i-tab { display: flex; align-items: center; justify-content: center; width: 30px; margin: 0 auto; height: auto; position: relative; z-index: 1;
            &::before { content: ""; display: block; padding-bottom: 100%; }
            &.big_img-tab {
              img { transform: translate(-50%, -50%) scale(1.2); }
            }
            i { font-size: 24px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
            img { width: 100%; height: 100%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); object-fit: contain; object-position: center; }
          }
          p { margin: 8px 0 0; display: block; line-height: 1; font-size: 10px; font-weight: 300; position: relative; z-index: 1; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
        }
      }
      .tab_two { width: 100%; display: grid; grid-template-columns: minmax(0,1fr) minmax(0,1fr); background: #fff; color: #4B4B4B;
        &:first-child { border-top-right-radius: 10px; padding-left: 10px; }
        &:last-child { border-top-left-radius: 10px; padding-right: 10px; }
        .tab_int { padding: 1rem 0.25rem; position: relative;
          &.disabled { pointer-events: none;
            img { filter: grayscale(1); opacity: 0.5; }
          }
          &.notifications::before { content: ""; position: absolute; top: 10px; right: 10px; width: 5px; height: 5px; border-radius: 100%; background-color: $primary-color; }
          &.active {
            .overlay { opacity: 0.1; }
          }
          .overlay { width: 100%; height: 100%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%) scaleX(0.9) scaleY(0.85); opacity: 0; z-index: 0; transition: all ease-in-out 0.3s; background: #000; border-radius: 8px; }
          .content_i-tab { display: flex; align-items: center; justify-content: center; width: 30px; margin: 0 auto; height: auto; position: relative; z-index: 1;
            &::before { content: ""; display: block; padding-bottom: 100%; }
            &.big_img-tab {
              img { transform: translate(-50%, -50%) scale(1.2); }
            }
            i { font-size: 24px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
            img { width: 100%; height: 100%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); object-fit: contain; object-position: center; }
          }
          p { margin: 8px 0 0; display: block; line-height: 1; font-size: 10px; font-weight: 300; position: relative; z-index: 1; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
        }
      }
      .tab_middle { width: 100%; position: relative;
        .bg_tab_middle { mask-image: url('../../assets/img/icons/srt.svg'); -webkit-mask-image: url('../../assets/img/icons/srt.svg'); mask-position: center 0%; -webkit-mask-position: center 0%; mask-repeat: no-repeat; -webkit-mask-repeat: no-repeat; mask-size: 115%; -webkit-mask-size: 115%; background-color: #fff; width: calc(100% + (8px * 2)); position: absolute; bottom: 0; left: 50%; height: 100%; transform: translateX(-50%); z-index: -1; }
        .option_tab { position: absolute; top: 0; left: 50%; transform: translate(-50%, -50%); width: 75%; border-radius: 50%; background-color: $primary-color; color: #fff;
          &.disabled { pointer-events: none; background-color: #b7b7b7; }
          &::before { content: ""; display: block; padding-bottom: 100%; }
          &.active_more-menu {
            div {
              i { transform: rotate(134deg); }
            }
          }
          div { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;
            i { display: inline-block; transition: all ease 0.3s; font-size: 19px; line-height: normal; }
            img { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 100%; height: 100%; object-fit: scale-down; object-position: calc(50% + 3px); }
          }
        }
        p { position: absolute; bottom: 0; font-size: 10px; margin: 0; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; padding: 1rem 0.25rem; line-height: 1; font-weight: 300; text-align: center; width: 100%; max-width: 100%; }
      }
    }
    .content-grid-options { display: none; max-width: 425px; position: absolute; bottom: 125px; left: 0; right: 0; width: calc(100% - 30px); margin: 0 auto 10px; border-radius: 20px; background-color: #fff; box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.30);
      &.active { display: block; }
      .content-optionts { padding: 20px 5px;
        .item-options { display: flex; flex-flow: column; align-items: center;
          i, img { width: 40px; height: 40px; line-height: 40px; text-align: center; font-size: 24px; color: #8e8e8e;
            &.img_icon { background: #8e8e8e; -webkit-mask-size: 72%; mask-size: 72%; -webkit-mask-repeat: no-repeat;  mask-repeat: no-repeat; -webkit-mask-position: center; mask-position: center; }
          }
          img { object-fit: contain; object-position: center; }
          .round-i { border-radius: 50%; background-color: rgba($primary-color, 0.18); font-size: 20px; color: $primary-color;
            /*& ~ label { color: var( --ion-color-primary ); }*/
          }
          label { font-size: 12px; margin-top: 5px; color: #8e8e8e; text-align: center; }
        }
      }
    }
  }
  .input_radio-button {
    input { appearance: none;
      &:checked ~ div { background-color: $primary-color; color: #fff; }
    }
    div { background: transparent; color: $primary-color; border: 1px solid $primary-color; }
  }

  .scrollbar_css {
    &::-webkit-scrollbar { width: 8px; height: 8px; }
    &::-webkit-scrollbar-track { box-shadow: inset 0 0 4px rgba(128,128,128,0.34); border-radius: 10px; }
    &::-webkit-scrollbar-thumb { background: #04aa6d; border-radius: 10px; }
    &::-webkit-scrollbar-thumb:hover { background: #05915e; }
    &.min_bar {
      &::-webkit-scrollbar { width: 6px; height: 6px; }
      &::-webkit-scrollbar-track { box-shadow: inset 0 0 2px rgba(128,128,128,0.34); border-radius: 6px; }
    }
  }
  .space__footer-scroll {
    .content_scroll-site, .ic__space { padding-bottom: 7rem; }
  }
  .content_splash-screen-cap { z-index: 9999999999999999999999999999; position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: $primary-color; display: flex; align-items: center; justify-content: center;
    &.hidden_splashscreen { display: none; }
    .mobile__splash-screen { width: 600px; display: block; margin: auto; max-width: 100%; }
    .mobile_lines::before, .mobile_lines::after { background-size: 200px; }
  }

  .required {
    &::after { content: " *"; color: red; }
  }

  .timeout_running_out { color: var(--primary-color); }

  .icon_hadouken { width: 60px; display: inline-block; }

  .list__recommendations { padding: 1rem; max-width: 100%; overflow-x: auto; overscroll-behavior-x: contain; will-change: scroll-position; touch-action: pan-x pan-y pinch-zoom; display: grid; gap: 1rem; grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr)); grid-auto-flow: column; grid-auto-columns: minmax(6rem,1fr);
    .item__recommendations { width: 100%; display: block; border: 0 solid transparent; border-radius: 50%; overflow: hidden; transition: all ease-in-out 0.15s; position: relative;
      &::before { content: ""; display: block; padding-bottom: 100%; }
      &:not(.not_active) { border-width: 3px; border-color: var(--third-color); background: white; }
      &.not__check {
        &::after { content: ""; display: block; position: absolute; top: 50%; left: 50%; width: 3px; height: 100%; transform: translate(-50%, -50%) rotate(45deg); background: #fff; transition: all ease-in-out 0.15s; }
        &:not(.not_active) { border-color: var(--primary-color); background: white;
          &::after { background: var(--primary-color); }
        }
      }
      img { border-radius: 50%; width: calc(100% - 3px); height: calc(100% - 3px); object-fit: cover; object-position: center; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
    }
  }

  .icon_validation_contain { position: relative;
    &.not__check {
      ion-icon.icon__validation { color: var(--primary-color); }
    }
    ion-icon.icon__validation { position: absolute; top: 0; right: 0; z-index: 2; font-size: 3rem; transform: translate(30%, -30%); color: var(--third-color); background-color: rgba(255, 255, 255, 0.58); border-radius: 50%; }
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration { display: none; }

  .content__list { display: flex; flex-flow: column; gap: 0.8rem; padding: 1rem;
    .content__option-list { display: block; width: 100%; padding: 1rem; font-size: 16px; line-height: normal; background-color: #fff; border-radius: 0.5rem;
      &.selected { background-color: var(--primary-color); color: #fff; }
    }
  }

  .editing__image { --cropper-overlay-color: #f7f7f7; background: #f7f7f7; min-height: 100%; width: 100%; padding: 1.5rem;
    image-cropper { padding: 0; width: 100%; display: flex; align-items: center; justify-content: center; overflow: unset;
      .ngx-ic-cropper { color: var(--primary-color);
        .ngx-ic-resize .ngx-ic-square { background: var(--primary-color) !important; }
      }
    }
  }
  .image__edited { position: fixed; bottom: 0; right: 0; width: 40%; max-width: 300px; height: fit-content; display: flex; align-items: center; justify-content: center; transition: all ease-in-out 0.25s; padding: 1rem; z-index: 2;
    &.complete__image { width: 100%; height: 100%; max-width: 100%; /* background: #000; */ background: #f7f7f7;
      img { border-radius: 0; border: none; box-shadow: none; }
    }
    img { border-radius: 8px; transition: all ease-in-out 0.25s; box-shadow: 0 0px 16px rgba(0, 0, 0, 0.651); border: 2px solid var(--primary-color); }
  }

  .content__stars-qualify {
    position: relative;
    width: 360px;
    max-width: 100%;
    display: block;
    margin: 0 auto;

    .rating-stars {
      display: flex;
      width: 100%;
      justify-content: center;
      overflow: hidden;
      flex-direction: row-reverse;
      position: relative;
      & > input {
        display: none;
      }
  
      & > label {
        cursor: pointer;
        width: 40px;
        height: 40px;
        margin-top: auto;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23e3e3e3' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 76%;
        transition: .3s;
      }
  
      & > input:checked ~ label,
      & > input:checked ~ label ~ label {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
      }
  
  
      & > input:not(:checked) ~ label:hover,
      & > input:not(:checked) ~ label:hover ~ label {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23e9bd18' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
      }
    }


  }

  .barcode__button {
    --background: var(--primary-color);
    --background-activated: #{darken($primary-color, 5%)};
    --background-focused: #{darken($primary-color, 5%)};
    --background-hover: #{lighten($primary-color, 5%)};
  }

  .button__pay-cart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 4rem;
    padding: 0.2rem 1.5rem;
    padding-right: 0.2rem;
    line-height: normal;
    background-color: #fff;
    color: var(--primary-color);
    font-weight: 600;
    span {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--primary-color);
      color: #fff;
      font-size: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .button__whatsapp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4rem;
    padding: 0.2rem 1.5rem;
    padding-left: 55px;
    line-height: normal;
    background-color: #fff;
    color: var(--third-color);
    font-weight: 600;
    transition: all ease-in-out 0.25s;
    position: relative;
    height: 46px;
    overflow: hidden;
    box-shadow: 0 2px 12px 0px rgba(0, 0, 0, 0.122);
    &:hover {
      div {
        transform: translateX(-3px);
      }
    }
    div {
      width: 100%;
      display: flex;
      gap: 15px;
      align-items: center;
      justify-content: flex-end;
      background-color: #fff;
      z-index: 2;
      height: 40px;
      transition: all ease-in-out 0.25s;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      transform: translateX(calc(-100% + 43px));
      overflow: hidden;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: #fff;
      span.icon__bw {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: var(--third-color);
        color: #fff;
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
      }
      span.text__hide {
        display: flex;
        align-items: center;
        gap: 15px;
      }
    }
  }

  .content__cart-items {
    width: 100%;
    padding: 10px;
    min-height: 100%;
    display: flex;
    justify-content: center;
    flex-flow: column;
    &.type__2 {
      min-height: unset;
      .content__cart-item {
        flex-wrap: wrap;
        .item__cart-image {
          width: 150px;
          align-self: start;
          .item__cart-image-elem {
            margin-top: 1rem;
          }
          .item__cart-image-open {
            top: 5px;
            right: 5px;
            button.btn__icon.icon__only.primary__btn {
              padding: 0.4rem;
            }
          }
        }
        h4 {
          font-weight: 600;
          font-size: 18px;
          line-height: normal;
        }
        h5 {
          padding: 0;
        }
        del {
          color: #afafaf;
          font-size: 75%;
          vertical-align: middle;
          font-weight: 400;
        }
        p, .item__cart-desc-info {
          font-size: 14px;
          line-height: normal;
        }
      }
    }
    &.type__3 {
      flex-flow: row;
      flex-wrap: wrap;
      justify-content: inherit;
      min-height: auto;
      .content__cart-item {
        flex-flow: column;
        .item__cart-image {
          width: 100%;
          .item__cart-image-elem .item__cart-image-elem-stock {
            font-size: 20px;
            padding: 10px;
            border-radius: 0 15px 15px 0;
            span {
              font-size: 12px;
            }
          }
        }
        .item__cart-desc {
          padding: 10px;
          h4 {
            font-weight: 600;
            font-size: 18px;
            line-height: normal;
          }
          h5 {
            padding: 0;
          }
          del {
            color: #afafaf;
            font-size: 75%;
            vertical-align: middle;
            font-weight: 400;
          }
          p, .item__cart-desc-info {
            font-size: 14px;
            line-height: normal;
          }
        }
      }
    }
    &.type__4 {
      flex-flow: row;
      flex-wrap: wrap;
      justify-content: inherit;
      min-height: auto;
      .content__cart-item {
        width: 100%;
        .item__cart-image {
          width: 100%;
          .item__cart-image-elem .item__cart-image-elem-stock {
            font-size: 20px;
            padding: 10px;
            border-radius: 0 15px 15px 0;
            span {
              font-size: 12px;
            }
          }
          .item__cart-image-quantity {
            position: absolute;
            z-index: 2;
            left: 0;
            top: 0;
            background-color: white;
            border-radius: 1rem;
            padding: 0px 5px;
          }
        }
        .item__cart-desc {
          padding: 0 10px 10px;
          h4 {
            font-weight: 600;
            font-size: 18px;
            line-height: normal;
          }
          del {
            color: #afafaf;
            font-size: 75%;
            vertical-align: middle;
            font-weight: 400;
          }
          p, .item__cart-desc-info {
            font-size: 14px;
            line-height: normal;
          }
        }
      }
    }
    .content__cart-item {
      display: flex;
      border-radius: 15px;
      overflow: hidden;
      background-color: #fff;
      margin-bottom: 10px;
      .item__cart-image {
        width: 90px;
        display: block;
        position: relative;
        padding: 10px;
        align-self: center;
        .count__items-product {
          position: absolute;
          top: 4px;
          left: 4px;
          z-index: 2;
          background-color: var(--primary-color);
          color: #fff;
          font-size: 13px;
          border-radius: 4rem;
          line-height: normal;
          padding: 4px 7px;
        }
        .item__cart-image-elem {
          width: 100%;
          display: block;
          position: relative;
          border-radius: 10px;
          overflow: hidden;
          &::before {
            content: "";
            display: block;
            padding-bottom: 100%;
          }
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .item__cart-image-open {
          position: absolute;
          top: 12px;
          right: 0;
          z-index: 2;
        }
      }
      .item__cart-desc {
        flex: 1 1 0%;
        padding: 10px;
        padding-left: 0;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        .item__cart-desc-title {
          padding: 10px 0;
          h5 {
            line-height: normal;
            font-size: 16px;
            padding-right: 5px;
            font-weight: 500;
          }
        }
        .item__cart-desc-price {
          display: flex;
          align-items: flex-start;
          h5 {
            flex: 1 1 0%;
            font-size: 14px;
          }
          button {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ebebeb;
            color: #000;
          }
        }
      }
      .item__cart-count {
        min-width: 60px;
        min-height: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        background-color: var(--primary-color);
        color: #fff;
        font-size: 16px;
        line-height: normal;
        padding: 6px;
        button {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #ffffff;
          color: var(--primary-color);
          font-size: 16px;
          &.item__cart-button-add {
            margin-bottom: 5px;
          }
          &.item__cart-button-remove {
            margin-top: 5px;
          }
        }
        .item__cart-count-number {
          display: block;
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  .item__cart-image-elem-stock {
    position: absolute;
    top: 12px;
    left: 0;
    z-index: 2;
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    border-radius: 0 10px 10px 0;
    background-color: rgba(var(--rgb-primary-color), 0.4);
    color: white;
    padding: 5px;
    line-height: normal;
    backdrop-filter: blur(2px);
    span {
      display: block;
      font-size: 10px;
      text-align: center;
      line-height: normal;
      font-weight: 400;
    }
  }

  .item__cart-buttons {
    margin-top: 10px;
    display: inline-flex;
    max-width: 100%;
    align-items: center;
    border: 1px solid #ddd;
    width: fit-content;
    border-radius: 10px;
    overflow: hidden;
    button {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border: 0;
      &.item__cart-button-remove {
        border-right: 1px solid #ddd;
      }
      &.item__cart-button-add {
        border-left: 1px solid #ddd;
      }
    }
    .item__cart-count-number {
      min-width: 45px;
      text-align: center;
      line-height: normal;
      display: block;
    }
  }

  .no_stock-opacity {
    opacity: 0.5;
    pointer-events: none;
  }

  .input__radio-color-grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .input__radio-color-grid-item {
      display: block;
      margin: 2px;
      width: 35px;
      aspect-ratio: 1 / 1;
      padding: 0;
      &:has(input:checked) {
        .input__radio-color-grid-item-radio {
          border-color: #ddd;
        }
      }
      input {
        display: none;
      }
      .input__radio-color-grid-item-radio {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: white;
        border: 2px solid white;
        transition: all ease-in-out 0.26s;
        padding: 3px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        .input__radio-color-grid-item-radio-color {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: white;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }

  .input__radio-option-grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .input__radio-option-grid-item {
      display: block;
      margin: 2px;
      width: auto;
      padding: 0;
      &:has(input:checked) {
        .input__radio-option-grid-item-radio {
          border-color: var(--primary-color);
          background-color: var(--primary-color);
          color: white;
        }
      }
      input {
        display: none;
      }
      .input__radio-option-grid-item-radio {
        display: block;
        position: relative;
        border-radius: 12px;
        background-color: white;
        border: 2px solid #ddd;
        transition: all ease-in-out 0.26s;
        padding: 5px 10px;
        min-width: 40px;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .content__cart-detail {
    width: 100%;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: normal;
    }
    p, .p__text {
      font-size: 14px;
      line-height: normal;
    }
  }

  .slide-space__down {
    &::part(container) {
      padding-top: 0.5rem;
      padding-bottom: 2rem;
    }
    .content__image {
      width: 100%;
      aspect-ratio: 1 / 0.6;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  swiper-container {
    --swiper-pagination-color: var(--primary-color);
  }

  .content__cart-fields {
    width: 100%;
    display: block;
    padding: 10px 0;
    .input__group {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  .input__group {
    width: 250px;
      max-width: 100%;
      display: inline-block;
    &.expand__field {
      width: 100%;
    }
    label {
      display: block;
      margin: 0;
      line-height: normal;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 5px;
      padding: 0;
      width: 100%;
    }
    input:not([type="radio"]), input:not([type="checkbox"]), select, textarea {
      width: 100%;
      display: block;
      border: 1px solid #ddd;
      border-radius: 8px;
      outline: none;
      padding: 0.6rem 0.8rem;
      appearance: none;
      background: transparent;
    }
    select {
      position: relative;
      background: transparent;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M0,0 L10,0 L5,7 z" fill="rgb(221, 221, 221)"/></svg>');
      background-repeat: no-repeat;
      background-position: right 14px center;
      background-size: 10px;
    }
    p {
      line-height: normal;
      margin: 0;
    }
  }

  @keyframes move-forever {
    0% {
     transform: translate3d(-90px,0,0);
    }
    100% { 
      transform: translate3d(85px,0,0);
    }
  }
  @keyframes up-down {
    0% {
      transform: translateY(0%);
    }
    50% { 
      transform: translateY(90%);
    }
    100% { 
      transform: translateY(0%);
    }
  }

  .content__circle-download {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 90px;
    height: 90px;
    background-color: #fff;
    transform: translateY(50%);
    border-radius: 50%;
    overflow: hidden;
    z-index: 2;
    padding: 10px;
    &.finish__download {
      .content__water-animation {
        .waveContainer {
          animation: up-down 6s 0;
          // .wave .waves  {
          //   opacity: 0; 
          //   visibility: hidden;
          // }
        }
      }
      &.error__download {
        .content__water-animation {
          .check__download {
            .download__icon {
              opacity: 0;
              visibility: hidden;
              position: absolute;
            }
            .error__icon {
              opacity: 1;
              visibility: visible;
              position: relative;
            }
          }
        }
      }
      &:not(.error__download) {
        .content__water-animation {
          .check__download {
            .download__icon {
              opacity: 0;
              visibility: hidden;
              position: absolute;
            }
            .check__icon {
              opacity: 1;
              visibility: visible;
              position: relative;
            }
          }
        }
      }
    }
    .content__water-animation {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
      transform: scale(1.1);
      .waveContainer {
        width: 100%;
        height: 100%;
        background: var(--primary-color);
        position: relative;
        animation: up-down 6s infinite;
        transition: all ease-in-out 0.3s;
        .wave {
          transform: rotate(180deg);
          width: 100%;
          height: 30%;
          position: relative;
          .waves {
            width: 100%;
            height: 100%;
            transition: all ease-in-out 0.3s;
            .parallax > use {
              animation: move-forever 18s cubic-bezier(.55,.5,.45,.5) infinite;
            }
            .parallax > use:nth-child(1) {
              animation-delay: -2s;
              animation-duration: 7s;
            }
            .parallax > use:nth-child(2) {
              animation-delay: -3s;
              animation-duration: 8s;
            }
            .parallax > use:nth-child(3) {
              animation-delay: -4s;
              animation-duration: 11s;
            }
            .parallax > use:nth-child(4) {
              animation-delay: -5s;
              animation-duration: 15s;
            }
          }
        }
      }
      .check__download {
        position: absolute;
        z-index: 1;
        color: #fff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .download__icon, .check__icon {
          transition: all ease-in-out 0.3s;
        }
        .check__icon, .error__icon {
          opacity: 0;
          visibility: hidden;
          position: absolute;
        }
      }
    }
  }

  .content__modal-download {
    .title__modal-download {
      padding: 1rem;
      padding-top: calc(50px + 0.5rem);
      background-color: var(--primary-color);
      border-radius: 20px 20px 0 0;
    }
    .desc__modal-download {
      padding: 1rem;
      background-color: #fff;
      border-radius: 0 0 20px 20px;
    }
  }

  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .content__circle-alert {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 90px;
    height: 90px;
    background-color: #fff;
    transform: translateY(50%);
    border-radius: 50%;
    overflow: hidden;
    z-index: 2;
    padding: 10px;
    &.error__alert .content__circle-animation .content__icon-status {
      color: var(--error-color);
    }
    &.success__alert .content__circle-animation .content__icon-status {
      color: var(--success-color);
    }
    &.info__alert .content__circle-animation .content__icon-status {
      color: var(--info-color);
    }
    &.warning__alert .content__circle-animation .content__icon-status {
      color: var(--warning-color);
    }
    .content__circle-animation {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
      -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
      animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
      .content__icon-status {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        color: var(--error-color);
        background-color: #f5f5f5;
        border-radius: 50%;
      }
    }
  }

  .content__modal-alert {
    .title__modal-alert {
      padding: 1rem;
      padding-top: calc(50px + 0.5rem);
      background-color: var(--primary-color);
      border-radius: 20px 20px 0 0;
    }
    .desc__modal-alert {
      padding: 1rem;
      background-color: #fff;
      border-radius: 0 0 20px 20px;
    }
  }

  .content__qr-contingency {
    border: 2px solid var(--primary-color);
    border-radius: 8px;
    color: var(--primary-color);
    font-size: 14px;
    width: fit-content;
    margin: 10px auto;
    padding: 4px 10px;
    line-height: normal;
    font-weight: 600;
    text-align: center;
    &.type__2 {
      background-color: rgba(var(--rgb-primary-color), 0.1);
      border-color: rgba(var(--rgb-primary-color), 0.1);
    }
    span {
      padding: 2px 5px;
      margin: 0;
      display: inline-block;
      color: var(--success-color);
      background-color: rgba(111, 225, 91, 0.149);
      border-radius: 4px;
      font-weight: 500;
    }
    .link__ctt {
      display: block;
      a {
        display: inline-block;
        color: var(--info-color);
        font-weight: 400;
        text-decoration: underline;
      }
    }
  }

  .qr__content {
    position: relative;
    .error__qr {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.439);
      display: flex;
      align-items: center;
      justify-content: center;
      .icon__status {
        color: var(--primary-color);
        width: 55px;
        height: 55px;
        background-color: #fff;
        border-radius: 50%;
        padding: 1rem;
      }
    }
  }

  .status__shift {
    border: 1px solid #ddd;
    box-shadow: 0 0 2px rgba(0, 0, 0, .20);
    &.shift-open {
      border-color: $third-color;
      box-shadow: 0 0 2px $third-color;
    }
    &.shift-close {
      border-color: $primary-color;
      box-shadow: 0 0 2px $primary-color;
    }
  }

  .stepper_full-height {
    .mat-horizontal-stepper-wrapper {
      height: 100%;
      .mat-horizontal-content-container {
        height: 100%;
        .mat-horizontal-stepper-content:not(.mat-horizontal-stepper-content-inactive) {
          height: 100%;
        }
      }
    }
  }

  .card__selector {
    position: relative;
    flex: none;
    display: block;
    text-align: center;
    background-color: white;
    color: #000;
    padding: 0.8rem 1.2rem;
    border-radius: 12px;
    border: 1px solid #ddd;
    margin: 2px;
    &:has(input:checked) {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
      color: white;
    }
    input[type="radio"] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      appearance: none;
      opacity: 0;
      cursor: pointer;
    }
    span {
      font-size: 16px;
    }

  }

  .introduction__slide {
    width: 100%;
    padding: 0;
    --swiper-pagination-color: var(--primary-color);
    --swiper-navigation-color: var(--primary-color);
    &::part(container) {
      padding-top: 2.25rem;
      padding-bottom: 2.25rem;
    }
  }

  .content__payment-methods {
    display: block;
    max-height: 250px;
    overflow: auto;
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(var(--rgb-primary-color), 0.05);
    .content__payment-method {
      display: flex;
      align-items: center;
      background-color: white;
      padding: 6px 10px;
      border-radius: 8px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.078);
      cursor: pointer;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      &.active {
        .content__check {
          background-color: var(--primary-color);
        }
      }
      .content__icon {
        width: 45px;
        aspect-ratio: 1 / 1;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
      .content__info {
        flex: 1;
        font-size: 14px;
        line-height: 1.2;
        padding: 0 10px;
        color: currentColor;
      }
      .content__check {
        margin-right: -10px;
        margin-top: -6px;
        margin-bottom: -6px;
        width: 50px;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ddd;
        color: white;
        font-size: 20px;
        border-radius: 0 8px 8px 0;
      }
    }
  }

  .text__legend {
    line-height: normal;
    width: 100%;
    display: block;
    font-size: 16px;
  }

  .custom__button-fab {
    position: absolute;
    transform: translateZ(0px);
    bottom: 10px;
    right: calc(10px + var(--ion-safe-area-right, 0px));
    width: fit-content;
    height: fit-content;
    z-index: 999;
    .barcode__button {
      margin: 0;
      width: 56px;
      height: 56px;
      --border-radius: 50%;
      font-size: 16px;
    }
  }

  .content__splashscreen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999999;
    background-color: var(--primary-color);
    .splash-screen-loading {
      width: 60px;
    }
  }

  .header__custom {
    // box-shadow: none;
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.1);
    ion-toolbar {
      --background: white;
      --color: black;
      --border-width: 0;
    }
    .header-background {
      backdrop-filter: none;
    }
    &.header__rounded {
      box-shadow: none;
      ion-toolbar {
        border-radius: 0 0 24px 24px;
        filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.08));
      }
    }
    &.transparent__toolbar ion-toolbar {
      --background: transparent;
      --color: white;
    }
    &.type__cart {
      ion-toolbar {
        --opacity: 1;
        --background: transparent;
        --color: white;
        ion-title {
          text-align: center;
        }
      }
    }
    ion-buttons ion-button {
      --color: var(--primary-color);
      --background: white;
      --border-radius: 8px;
      i {
        font-size: 24px;
      }
    }
    .content__logo {
      width: 120px;
      padding: 8px 10px;
    }

    ion-tab-bar {
      --background: transparent;
      --color: white;
      ion-tab-button {
        transition: all ease-in-out 0.25s;
        &.tab-selected {
          --background: white;
          border-radius: 10px;
        }
      }
    }

    ion-menu-button {
      font-size: 22px;
    }

    ion-searchbar {
      --border-radius: 8px;
      --background: white;
      --color: black;
      padding: 8px 15px;
    }

    ion-title {
      font-size: 16px;
    }
  }
  .footer__custom {
    box-shadow: none;
    .content__footer {
      padding: 0 10px;
    }
    &.footer__shadow {
      box-shadow: 0 -2px 10px -1px rgba(0, 0, 0, 0.08);
    }
  }

  .border__bottom-dot:not(:last-child) {
    border-bottom: 1px dotted #ddd;
    padding-bottom: 6px;
  }

  .content__methods-payment-fast {
    width: 100%;
    padding-bottom: 1.5rem;
    position: relative;
    --swiper-pagination-color: var(--primary-color);
    --swiper-pagination-bottom: -3px;
    .swipper__services {
      --swiper-pagination-bottom: 13px;
      &::part(container) {
        padding-bottom: 3rem;
      }
    }
    .content__method-payment {
      display: block;
      position: relative;
      margin: 10px;
      .content__method-payment-img {
        aspect-ratio: 1 / 1;
        position: relative;
        background-color: white;
        border-radius: 16px;
        box-shadow: 0 2px 8px 0px rgba(0, 0, 0, 0.102);
        border: 1px solid #efefef;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        img {
          display: block;
          width: 80px;
          max-width: 100%;
          max-height: 100%;
        }
      }
      .content__method-payment-text {
        font-size: 14px;
        text-align: center;
        margin: 0;
        margin-top: 10px;
        line-height: normal;
        color: rgba(var(--rgb-primary-color), 0.5);
      }
      .head__status {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        padding: 8px;
        width: 40px;
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: 0 0 0 10px;
        backdrop-filter: blur(2px);
      }
    }
    .swiper__btns {
      position: absolute;
      top: 0;
      bottom: 78px;
      margin: auto 0;
      height: fit-content;
      z-index: 2;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      color: var(--primary-color);
      cursor: pointer;
      &.swiper-button-prev {
        left: -12px;
      }
      &.swiper-button-next {
        right: -12px;
      }
      &.swiper-button-disabled {
        display: none;
      }
    }
  }

  .bg__q-logo {
    background-color: white;
    overflow: hidden;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 500px;
      max-width: 100%;
      height: 100%;
      background-image: url('../../assets/img/payment-receipt/logo_q.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 75%;
      z-index: 1;
      opacity: 0.05;
      
    }
  }

  // AÑADIR ESTILOS DEBAJO DE AQUÍ
  // ---

}

/*
* IOS
*/
.ios, .plt-capacitor.plt-ios {
  body.webapp-site {
    .header_space-ios { padding-top: 1.5rem; }
    .footer_space-ios { padding-bottom: 1.5rem !important; }
    .content_tabs .content_tabs-wrapper {
      .tab_two { padding-bottom: 1rem; }
      .tab_middle p { padding-bottom: 2rem; }
    }
  }
}

/*
* Desktop Versión
*/

.mat-tab-labels {
  justify-content: space-around;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #f44336;
}

.item-native{
  background: transparent;
}

.img-icon{
  shape-outside: url('../../assets/img/icon-p.svg');
  shape-image-threshold: 0.5;
  shape-margin: 20px;
  float: left;
  margin-top: -40px;
}

.mat-horizontal-content-container {
  padding: 0px !important;
}

.mat-horizontal-stepper-header{
  padding: 0 12px !important;
}

.line-link{
  border-bottom: 1px solid var(--primary-color);
}

.take-photo{
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border:1px solid var(--primary-color);
  background-color: var(--primary-color);
  border-radius: 8px;
  color: white;
  gap: 8px;
  font-weight: 500;
  width: fit-content;

}

.txt-p{
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgb(107, 114, 128);
  margin-bottom: 8px;
  font-weight: 500;

  span{
    color: var(--primary-color);
    font-weight: 600;
  }

}

.txt-color{
  color: var(--primary-color);
  font-weight: 600;
}
