/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */

// * Principal Theme Dark
// @import '@ionic/angular/css/palettes/dark.system.css';

@import url('./assets/xtras/bootstrap-grid/bootstrap-grid.css');

// Init
$font-path: "./assets/fonts";
// ICONS CAP
@import url('#{$font-path}/font-awesome/all.css');
@import url('#{$font-path}/linearicon/linearicon.css');
@import url('#{$font-path}/themify/themify.css');

// Font Family
/*--Fonts--*/
@import url('#{$font-path}/font_montserrat/font_montserrat.css');
@import url('#{$font-path}/font_roboto/font_roboto.css');

@import url('https://fonts.googleapis.com/css2?family=Economica:ital,wght@0,400;0,700;1,400;1,700&display=swap');

html, body {
    height: 100%;
}
body {
    margin: 0;
    font-family: var(--font-text);
    --ion-font-family: var(--font-text);
}

.font__title {
    font-family: var(--font-title);
}
.font__subtext {
    font-family: var(--font-subtext);
}
.font__text {
    font-family: var(--font-text);
}

* {
    -webkit-tap-highlight-color: transparent;
}