// body.webapp-site {
    // custom
    @media screen and (min-width: 500px) {
        body.webapp-site app-root .root__page:not(.auth-page) ion-app.main-site .content_tabs .content_tabs-wrapper {
            .tab_one {
                &.tab_first { border-top-left-radius: 30px; }
                &.tab_last { border-top-right-radius: 30px; }
            }
            .tab_two {
                &:first-child { border-top-left-radius: 30px; }
                &:last-child { border-top-right-radius: 30px; }
            }
        }
    }

    // sm Tailwind
    @media screen and (min-width: 640px) {
        .loader_content.type_2 img { width: 80%; height: 80%; }
        body.webapp-site {
            .content__cart-items.type__3 .content__cart-item {
                flex: 1 0 calc((100% / 2) - (10px));
                max-width: calc((100% / 2) - (10px));
                margin: 5px;
            }
        }
    }

    // md tailwind
    @media screen and (min-width: 768px) {
        body.webapp-site {
            .content__cart-items.type__3 .content__cart-item {
                flex: 1 0 calc((100% / 3) - (10px));
                max-width: calc((100% / 3) - (10px));
            }
        }
    }

    @media screen and (min-width: 992px) {
        /*
            * Desktop Versión
        */
        .plt-desktop, .plt-mobileweb {
            body.webapp-site { background: var(--primary-color);
                app-root { display: flex; align-items: center; justify-content: center;
                    .root__page:not(.auth-page) { width: 900px; max-width: 100%; position: relative; /* width: 550px; position: relative; border: 22px solid #ffffff; border-radius: 30px; filter: drop-shadow(0px 7px 10px rgba(0,0,0,0.5)); */ background: #ffffff; border: 15px solid #ffffff; border-bottom: 0; border-top-left-radius: 20px; border-top-right-radius: 20px; box-shadow: 0 0 20px rgba(0, 0, 0, 0.26);
                        ion-app.main-site { /* border-radius: 12px; box-shadow: 0 0 1px rgba(0, 0, 0, 0.32); overflow: hidden; */
                            ion-modal{
                                &.modal__sheet, &.success__modal { visibility: visible; --backdrop-opacity: 0.2; }
                            }
                            .content_tabs .content_tabs-wrapper {
                                .tab_middle p { font-size: 12px; /* padding: 0.5rem 0.25rem; */ }
                                .tab_two .tab_int { /* padding-bottom: 0.5rem; */
                                    p {margin: calc(8px + 0.5rem) 0 0; font-size: 12px; }
                                }
                            }
                        }
                    }
                }
                .hide_scrollbar { -ms-overflow-style: initial; scrollbar-width: initial;
                    &::-webkit-scrollbar { display: unset; -webkit-appearance: none; width: 7px; }
                    &::-webkit-scrollbar-thumb { border-radius: 4px; background-color: rgba(0, 0, 0, .5); box-shadow: 0 0 1px rgba(255, 255, 255, .5); }
                    &::-webkit-scrollbar-thumb:hover { background: #555; }
                }
                ion-content:part(scroll), ion-content {
                    &::-webkit-scrollbar { display: unset; -webkit-appearance: none; width: 7px; }
                    &::-webkit-scrollbar-thumb { border-radius: 4px; background-color: rgba(0, 0, 0, .5); box-shadow: 0 0 1px rgba(255, 255, 255, .5); }
                    &::-webkit-scrollbar-thumb:hover { background: #555; }
                }
            }
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 1440px) and (min-height: 520px) and (max-height: 800px) {
        body.webapp-site {
            app-root {
                .root__page {
                    &.auth-page {
                        zoom: 80%;
                    }
                }
            }
        }
    }
    
    @media screen and (min-width: 1024px) {
        // Tailwind lg
    }

    @media screen and (min-width: 361px) and (max-width: 767px) {
        
    }

    @media screen and (max-width: 1023px) {
        .plt-desktop, .plt-mobileweb {
            body.webapp-site app-root { justify-content: center; }
        }
    }

    @media screen and (max-width: 991px){
        
    }

    @media screen and (max-width: 767px) {
        body.webapp-site .content_splash-screen-cap .bg_lines { 
            &::before, &::after { background-size: 200px; }
        }
    }

    @media screen and (max-width: 639px) {
        body.webapp-site {
        }
    }

    // Custom
    @media screen and (max-width: 500px) {
        
    }
    
    
// }