$primary-color: #EA5455;
$second-color: #009db3;
$third-color: #00b275;
$fourth-color: #5d5d5d;

// rgb
$rgb-primary-color: 252, 36, 20;
$rgb-second-color: 0, 157, 179;
$rgb-third-color: 0, 178, 117;
$rgb-fourth-color: 93, 93, 93;

$error-color: #ff4a4a;
$warning-color: #ffd227;
$info-color: #4ac6ff;
$success-color: #40d565;

$font-title: 'Montserrat', sans-serif;
$font-subtext: 'Montserrat', sans-serif;
$font-text: 'Roboto', sans-serif;

:root {
    --primary-color: #{$primary-color};
    --second-color: #{$second-color};
    --third-color: #{$third-color};
    --fourth-color: #{$fourth-color};
    // for RGBA
    --rgb-primary-color: #{$rgb-primary-color}; //--primary-color *
    --rgb-second-color: #{$rgb-second-color}; //--second-color *
    --rgb-third-color: #{$rgb-third-color}; //--third-color *
    --rgb-fourth-color: #{$rgb-fourth-color}; //--fourth-color *
    
    --error-color: #{$error-color};
    --warning-color: #{$warning-color};
    --info-color: #{$info-color};
    --success-color: #{$success-color};
    
    --ion-color-primary: var(--primary-color);

    --font-title: #{$font-title};
    --font-subtext: #{$font-subtext};
    --font-text: #{$font-text};

    --ion-font-family: #{$font-text};
}